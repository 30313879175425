import '../styles/playerStats.css';

import React from 'react';

const PlayerStats = () => {
  return (
    <div>
      <div id="playerStats">
        <h1>GATTO WIF ~ MEOOOOWWW WIFF WIIFF</h1>
        <div id="playerStats__lines">
          <span id="playerStats__lines__thick"></span>
          <span id="playerStats__lines__thin"></span>
        </div>
          <div id="player" className="link-container">
        <a href="https://raydium.io/" target="_blank" rel="noopener noreferrer">
          BUY
        </a>
      </div>
      <h2>CA: updating soon</h2>
      <div id="player" className="link-container">
        <a href="https://dexscreener.com/solana/" target="_blank" rel="noopener noreferrer">
          CHART
        </a>
      </div>
      <div id="player" className="link-container">
        <a href="https://twitter.com/Gattowif" target="_blank" rel="noopener noreferrer">
          Twitter|X
        </a>
      </div>
      
      <div id="player" className="link-container">
        <a href="https://t.me/+VW4Jpnrlr0oyMmE0" target="_blank" rel="noopener noreferrer">
          Telegram
        </a>
      </div>
      
      </div>

    </div>
  );
};

export default PlayerStats;
